import React, { Component } from 'react';

export default class Code extends Component{

  render(){
    return(
      <div>
        <h2>Code page</h2>
        {console.log("Code")}
      </div>
    );
  }
}
