import React, { Component } from 'react';

export default class CV extends Component{

  render(){
    return(
      <div>
        <h2>CV page</h2>
        {console.log("CV")}
      </div>
    );
  }
}
